import React from 'react'
import { Input } from 'reactstrap'
import moment from 'jalali-moment'
import Parameters from '../Engine/Parameters';
const ReadonlyObjectControl = ({ prop,control }) => {
    let value =prop?.DIS ?? prop?.IPV;
    // if(control.DIPV && prop && (prop.IPV===undefined)){
    //     value=Parameters.GetValue(control.DIPV);
    //     console.log(prop,control,value)
    //     prop.IPV=value.id;
    //     prop.DIS=value.display;

    // }
    if(prop?.IPV && control.controlType==='TimePeriod')
        value= moment(prop?.IPV, 'MM/DD/YYYY').locale('fa').format('YYYY/MM/DD');
    return <Input aria-label="Search" bsSize='sm'
        className="search-input" value={value}
        key={prop?.IPV ?? 'notloaded'} disabled
         />
}
export default ReadonlyObjectControl;