import BaseInstance, { NewInstance } from "../Engine/BaseInstance";
import { FromManagerDataTemplate } from "../Engine/FormDataTemplate";
import { InstanceController } from "../Engine/InstanceController";
import { toast } from 'react-toastify';
import ConditionMaker from "../Engine/ConditionMaker";
import { NewForm, Utility } from "../Engine/Common";
import ObjectClassController from "../Engine/ObjectClassController";

export default class DataManager {
    constructor(component, formid, actionType) {
        this.Component = component;
        this.ActionType = actionType;
        this.MainFormID = formid
    }
    Component;
    ActionType;
    MainFormID;
    Storage = [];
    Template = null;
    Triger;
    Connectors;
    TemplateTriger = [];
    FormStructuer;
    onChangeProp;
    feedBack;
    get formStructuer() {
        let result = this.Component.state.FormStructuer;

        return result;
    }
    get state() {
        return this.Component.state;
    }
    SetState(FormData) {
        this.Component.setState({
            ...this.Component.state,
            FormData,
            loadData: false
        })
    }
    Load(start) {
        this.Component.setState({
            ...this.Component.state,
            loadData: start
        })
    }
    async Save(formId, callback) {
        this.Load(true);

        if (this.ActionType === 'template') {
            if (this.Template === null)
                this.Template = new FromManagerDataTemplate();
            let keyValue = this.TemplateConditionMaker();
            if (keyValue) {
                this.Template.ConditionValue = keyValue
                this.Template.Json = JSON.stringify(this.Storage);
                await this.Template.SaveAsync();
                this.Load(false);
            }
            else
                toast.error('opation did not work')
        }
        else {
            if (await this.Validation()) {
                if (this.Connectors)
                    await this.SaveReletedData();
                else {
                    let temp = this.Storage.find(x => x.formId === formId);
                    if (temp) {
                        let instance = new BaseInstance(temp.data);
                        if (this.formStructuer?.BeforeSave)
                            eval(this.formStructuer?.BeforeSave)
                        await instance.SaveAsync();
                    }
                    if (callback) {
                        let tempIndex = this.Storage.findIndex(x => x.formId === callback);
                        this.Storage.splice(tempIndex, tempIndex < 0 ? 0 : 1, temp);
                    }
                    if (this.feedBack)
                        this.feedBack(temp.data, 'Save');
                    this.SetState(this.Storage);
                }
            }
            else this.Load(false);
        }
    }
    async Validation() {
        console.log(this.Storage)
        let result = this.Storage?.length > 0;
        for (let i = 0; i < this.Storage.length; i++) {
            const formdata = this.Storage[i];
            if (formdata.data instanceof Array) {
                for (let j = 0; j < formdata.data.length; j++) {
                    const insrow = formdata.data[j];
                    let tempresult = await this.CheckForm(insrow)
                    if (result)
                        result = tempresult;
                }
            }
            else {
                result = await this.CheckForm(formdata.data)
            }
        }
        return result;
    }
    async CheckForm(instance) {
        let ins = new BaseInstance(instance);
        let result = true;
        if (instance.Prop) {
            let oc = await ObjectClassController.LoadAsync(Utility.GetClassID(instance.ID));
            for (let i = 0; i < oc?.Extended?.Required?.length; i++) {
                const propid = oc.Extended?.Required[i];
                const prop = ins.GetProperty(propid);
                prop.hasError = (!prop.IPV || prop.IPV === '')
                if (result)
                    result = !prop.hasError;
            }
        } else {
            return false;
        }
        return result;
    }
    async SaveReletedData() {
        let header = this.Storage.find(x => x.formId === this.Connectors[0].SF);
        let propid = this.Connectors[0].BP;
        for (let index = 0; index < this.Connectors.length; index++) {
            let details = this.Storage.find(x => x.formId === this.Connectors[index].BF);
            let result = await InstanceController.SaveRelatedInstancesAsync(header.data, propid, details.data);
            header.data.ID = result.Header.ID
            console.log(result, header.data.ID);
        }
        this.SetState(this.Storage);
    }
    async ChangeData(value, pid, Data, TP) {
        if (typeof Data === 'string')
            Data = this.Storage.find(x => x.formId === Data)?.data;
        new BaseInstance(Data).SetValue(pid, value, TP);
        console.log(this.Storage, Data)
        await this.FireTemplateTriger(pid, value);
        if (this.ActionType !== 'template')
            await this.FireTriger(pid, value);
        if (this.onChangeProp)
            this.onChangeProp(value, pid, Data);
        this.SetState(this.Storage);
        return this.Storage;
    }
    async Refresh(formId) {
        this.Load(true);
        let data = await InstanceController.GetInstancesAsync(formId.split('F')[0]);
        let tempIndex = this.Storage.findIndex(x => x.formId === formId);
        let temp = this.Storage[tempIndex];
        if (!temp)
            temp = { formId };
        temp.data = data;
        this.Storage.splice(tempIndex, tempIndex < 0 ? 0 : 1, temp);
        this.SetState([...this.Storage]);
    }

    New(formId, type) {
        let tempIndex = this.Storage?.findIndex(x => x.formId === formId);
        if (type === '0') {
            let temp = { formId, data: NewInstance(formId.split('F')[0]) };
            this.Storage.splice(tempIndex, tempIndex < 0 ? 0 : 1, temp);
        }
        else if (type === '1') {
            let source = tempIndex < 0 ? { formId: formId, data: [] } : this.Storage[tempIndex];
            source.data = [...source.data, NewInstance(formId.split('F')[0])];
            this.Storage.splice(tempIndex, tempIndex < 0 ? 0 : 1, source);
        }
        this.SetState(this.Storage);
    }
    SendToEditor(instanse, formId, Editor) {
        let tempIndex = this.Storage.findIndex(x => x.formId === Editor);
        let temp = { formId: Editor, data: instanse, callback: formId };
        this.Storage.splice(tempIndex, tempIndex < 0 ? 0 : 1, temp);
        console.log(tempIndex)
        this.SetState(this.Storage);
    }
    Delete() {

    }
    FillProp(formId, pid) {
        let form = this.Storage.find(x => x.formId === formId);
        if (!form.fillProp)
            form.fillProp = [];
        let index = form.fillProp.findIndex(x => x === pid);
        if (index >= 0)
            form.fillProp.splice(index, 1);
        else
            form.fillProp = [...form.fillProp, pid];
        this.SetState(this.Storage);
        console.log(form, this.Storage)
    }
    ChangeStepSource(list, pid, Data) {
        let prop = new BaseInstance(Data).GetProperty(pid);
        prop.stepSource = list;
        this.SetState(this.Storage);
    }
    GetPropertyValue(formid, pid) {
        let formdata = this.Storage.find(x => x.formId === formid);
        if (formdata) {
            return new BaseInstance(formdata.data).GetValue(pid);
        }
        return null;
    }
    async FireTemplateTriger(pid) {
        if (this.TemplateTriger?.findIndex(x => x.PID === pid) >= 0) {
            let keyValue = this.TemplateConditionMaker();
            if (keyValue) {
                this.Load(true);
                this.Template = await FromManagerDataTemplate.LoadByAsync(keyValue);
                if (this.Template !== null) {
                    this.Storage = JSON.parse(this.Template.Json);
                    this.SetState(this.Storage)
                }
            }
        }
        return this;
    }
    TemplateConditionMaker() {
        let keyValue = this.MainFormID + '@';
        let counter = 0;
        for (var i = 0; i < this.TemplateTriger?.length; i++) {
            let temppid = this.TemplateTriger[i].PID;
            let value = this.GetPropertyValue(this.TemplateTriger[i].formId, temppid);
            if (value) {
                keyValue += temppid + ':' + value + '&';
                counter++;
            }
            else
                break;
        }
        if (this.TemplateTriger?.length === counter) {
            return keyValue;
        }
        return null;
    }
    async FireTriger(pid) {
        if (this.Triger && this.Triger[0]?.PIDs?.findIndex(x => x === pid) >= 0) {
            let data1 = await this.DataConditionMaker();
            if (data1) {
                let data = JSON.parse(JSON.stringify(data1));
                if (Utility.IsInstanceID(data?.Header?.ID) || (Array.isArray(data) && data?.length > 0 && Utility.IsInstanceID(data[0].ID))) {
                    if (this.Template) {
                        let newStorage = [];
                        let template = JSON.parse(this.Template.Json)
                        if (this.Connectors) {
                            for (let index = 0; index < this.Connectors.length; index++) {
                                let form = template.find(x => x.formId === this.Connectors[index].SF);
                                let header = data.Header;
                                if (form?.data && newStorage.findIndex(x => x.formId === form.formId) < 0) {
                                    newStorage = [{ formId: form.formId, data: this.syncData(header, form.data) }]
                                }
                                let details = template.find(x => x.formId === this.Connectors[index].BF)
                                let newdata = [];
                                for (var i = 0; i < details.data.length; i++) {
                                    let pid = details.fillProp[0];
                                    let filltempProp = new BaseInstance(details.data[i]).GetProperty(pid);
                                    let fillValueinstance = data.RelatedInstances.find((ins) => new BaseInstance(ins).GetValue(pid) === filltempProp.IPV);
                                    newdata = [...newdata, this.syncData(fillValueinstance, details.data[i])]
                                }
                                newStorage = [...newStorage, { formId: this.Connectors[index].BF, data: newdata }]
                            }
                            this.Storage = newStorage;

                            this.SetState(this.Storage);
                        }

                    }
                    else {
                        let currrentdata = this.Storage[0].data;
                        this.syncData(currrentdata, data[0], true);
                        this.SetState(this.Storage);

                    }
                }
                else {
                    if (this.Template?.Json) {
                        let template = JSON.parse(this.Template.Json)
                        if (this.Connectors) {
                            let newStorage = [];
                            let header = template.find(x => x.formId === this.Connectors[0].SF);
                            let form = this.Storage.find(x => x.formId === this.Connectors[0].SF);
                            newStorage = [{ formId: this.Connectors[0].SF, data: this.syncData(header.data, form.data, true, this.Triger[0]?.PIDs) }];
                            for (let index = 0; index < this.Connectors.length; index++) {
                                let details = template.find(x => x.formId === this.Connectors[index].BF)
                                newStorage = [...newStorage, { formId: this.Connectors[index].BF, data: details.data }]
                            }
                            this.Storage = newStorage;
                            this.SetState(this.Storage)
                        }
                    }
                    else {
                        this.Storage[0].data = this.syncData({ ...NewInstance, ID: Utility.GetClassID(this.Storage[0].formId) },
                            this.Storage[0].data, true, this.Triger[0]?.PIDs);
                        this.SetState(this.Storage);
                    }
                }
            }
        }

    }
    syncData(base, source, withValue, props) {
        let result;
        if (base && source) {
            let baseIns = new BaseInstance(base);
            let SourceIns = new BaseInstance(source);
            let ides = SourceIns.getPropertyIds();
            if (props)
                ides = props;
            for (var i = 0; i < ides.length; i++) {
                let stepSource = SourceIns.GetProperty(ides[i]).stepSource;
                let tempProp = baseIns.GetProperty(ides[i]);
                if (stepSource && tempProp)
                    baseIns.GetProperty(ides[i]).stepSource = stepSource;
                let value = SourceIns.GetValue(ides[i], withValue);
                if (value && !baseIns.GetValue(ides[i]))
                    baseIns.SetValue(ides[i], value);
            }
            result = baseIns.Instance;
        }
        else {
            result = { ...source };
        }
        return result;
    }
    async DataConditionMaker() {
        let counter = 0;
        for (var i = 0; i < this.Triger?.length; i++) {
            let condition = new ConditionMaker(Utility.GetClassID(this.Triger[i].formId))
            let temppid = this.Triger[i].PIDs;
            for (var j = 0; j < temppid.length; j++) {
                let value = this.GetPropertyValue(this.Triger[i].formId, temppid[j]);
                if (value) {
                    condition.AddCondition(temppid[j], '=', value, j + 1 < temppid.length ? 'AND' : undefined);
                    counter++;
                }
                else
                    break;
            }
            if (temppid.length === counter) {
                this.Load(true);
                let result;
                if (!this.Connectors)
                    result = await condition.GetResult();
                else {
                    let related = [];
                    for (let i = 0; i < this.Connectors.length; i++) {
                        related = [...related, Utility.GetClassID(this.Connectors[i].BF)]
                    }
                    result = await InstanceController.GetRelatedInstancesAsync(JSON.stringify(condition)
                        , this.Connectors[0].BP, related)

                }
                return result;
            }
        }
        return undefined;
    }
    async InitialTemplate(form) {
        if (form?.TempCon?.length > 0) {
            this.TemplateTriger = form.TempCon;
        }
        if (form?.Connectors?.length > 0) {
            this.Connectors = form.Connectors;
        }
        if (form?.Con?.length > 0) {
            this.Triger = form.Con;
        }
        this.Load(false);
    }
    ChangeForm(form) {
        if (!form)
            this.Component.setState({
                ...this.Component.state,
                LastFormId: this.MainFormID,
                loadData: false,
                FormStructuer: { ...this.FormStructuer },
            })
        else {
            this.Component.setState({
                ...this.Component.state,
                FormStructuer: form,
            })
        }
    }
    ChangeState(state) {
        this.Component.setState(state)
    }
    SetData(formId, data) {
        let temp = this.Storage.findIndex(x => x.formId === formId && x?.data.ID === data.ID);
        // if (temp < 0) {
        //     this.Storage = [{ formId, data }]
        this.SetState(this.Storage)
        //}
    }
    async LoadDataAsync(id) {

        if (Utility.IsInstanceID(id)) {
            let ins = await InstanceController.LoadInstanceAsync(id);
            let formdataIndex = this.Storage.findIndex(s => s.formId.includes(Utility.GetClassID(id)));
            if (formdataIndex >= 0) {
                this.Storage[formdataIndex].data = ins;
                if (this.TemplateTriger?.length > 0)
                    await this.FireTemplateTriger(this.TemplateTriger[0].PID);
                this.Storage.find(s => s.formId.includes(Utility.GetClassID(id))).data = ins;
                if (this.Triger?.length > 0)
                    await this.FireTriger(this.Triger[0]?.PIDs[0]);
                this.SetState(this.Storage)
            }
        }
    }
}