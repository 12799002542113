import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardFooter, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Row } from 'reactstrap';
import DatePicker from "react-datepicker";
import { Duty, GetHumanResources } from './wmsContext';
import Flex from '../components/common/Flex';
import FalconCardHeader from '../components/common/FalconCardHeader';
import AvatarControl from '../EngineForms/AvatarControl';
import moment from 'moment';

const DutyHandler = ({ isOpen, toggle, duty, style,Cancel }) => {
    useEffect(() => {
        const fetch = async () => {
            if (!humans)
                setHumans(await GetHumanResources())
        }
        fetch();
    })
    const [humans, setHumans] = useState();
    const [data, setData] = useState(new Duty());
    const [isOpenDoer, setIsOpenDoer] = useState(false);
    const [isOpenSuper, setIsOpenSuper] = useState(false);
    const Save = async() => {
        await data.SaveAsync();
    }
    return (
        <Card style={style}>
            <FalconCardHeader title='Task' />
            <CardBody>
                <FormGroup>
                    <Label>Title</Label>
                    <Input name='title' className='form-control' onChange={({target})=>{data.Title=target.value;setData(new Duty(data.Instance))}} />
                </FormGroup>
                <Row>
                    <Col>
                    <Label>Due Timme</Label>
                        <DatePicker
                            selected={data.StartTime?new Date(data.StartTime):''} bsSize="sm"
                            onChange={(date) =>{data.StartTime=date.getMonth()+'/'+ date.getDate()+'/'+ date.getFullYear(); setData(new Duty(data.Instance))}}
                            formatWeekDay={day => day.slice(0, 3)}
                            className='form-control'
                            placeholderText={'Due Time'}
                        />
                    </Col>
                    <Col>
                    <Label>Deadline</Label>
                        <DatePicker
                            selected={data.EndTime?new Date(data.EndTime):''} bsSize="sm"
                            onChange={(date) => {data.EndTime=date.getMonth()+'/'+ date.getDate()+'/'+ date.getFullYear(); setData(new Duty(data.Instance))}}
                            formatWeekDay={day => day.slice(0, 3)}
                            className='form-control'
                            placeholderText={'deadline'}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label for="exptime">Expected Duration Time
                        </Label>
                        <Input id="exptime" type='number' defaultValue={data.ExpectedTime??''} 
                        onChange={({target})=>{data.ExpectedTime=target.value; setData(new Duty(data.Instance))}} />
                    </Col>
                </Row>
                    
                <Row>
                    <Col>
                        <Label>Doer</Label>
                        <Dropdown isOpen={isOpenDoer} toggle={() => setIsOpenDoer(!isOpenDoer)}>
                            <DropdownToggle className='p-0 block' color='white'>
                                <Input className='text-truncate' readOnly value={data?.PersonTeam?data?.GetProperty('PC38').DIS:''}  >

                                </Input>
                            </DropdownToggle>
                            {humans?
                            <DropdownMenu>
                                {
                                    humans?.map((item) => (
                                        <DropdownItem key={item.ID} onClick={() =>{data.PersonTeam=item; setData(new Duty(data.Instance))}}>
                                            <AvatarControl prop={item.GetProperty('PC105')} name={item.DIS} />
                                        </DropdownItem>
                                    ))
                                }
                            </DropdownMenu>:null}
                        </Dropdown>
                    </Col>
                    <Col>
                    <Label>Supervisor</Label>
                        <Dropdown isOpen={isOpenSuper} toggle={() => setIsOpenSuper(!isOpenSuper)}>
                            <DropdownToggle className='p-0 block' color='white'>
                                <Input className='text-truncate' readOnly value={data?.Supervisor?data?.GetProperty('PC37').DIS:''} onChange={() => {}} >

                                </Input>
                            </DropdownToggle>
                            {humans?
                            <DropdownMenu>
                                {
                                    humans?.map((item) => (
                                        <DropdownItem key={item.ID} onClick={() =>{data.Supervisor=item;setData(new Duty(data.Instance))}}>
                                            <AvatarControl prop={item.GetProperty('PC105')} name={item.DIS} />
                                        </DropdownItem>
                                    ))
                                }
                            </DropdownMenu>:null}
                        </Dropdown>
                    </Col>
                </Row>
                <Row>
                    <CardBody>
                        <FormGroup>
                            <Label>Comment</Label>
                            <Input style={{ height: '200px' }} type='textarea' onChange={({ target }) =>{data.Comment=target.value; setData(new Duty(data.Instance))}} />
                        </FormGroup>
                    </CardBody>
                </Row>
            </CardBody>
            <CardFooter>
                <Flex justify='end'>
                <Button color='light' onClick={()=>Cancel?Cancel():null}>Cancel</Button>
                <Button color='primary' onClick={Save}>Save</Button>
                </Flex>
            </CardFooter>
        </Card>
    );
};

export default DutyHandler;