import axios from 'axios'
import { AuthenticationController } from './Authentication';
export const settings = {
  Server: `http://3.96.30.35:8654/api/V1/`,
  headers: { ContentType: 'application/json; charset=utf-8;', 'Authorization': `Bearer ${localStorage.getItem('token')}` },
  lang: ['en-US']
};
axios.defaults.baseURL = settings.Server;
axios.defaults.timeout = 50000;
axios.defaults.headers = settings.headers;
var axiosInstance = axios.create();
axiosInstance.interceptors.request.use(
  config => {
    config.headers = settings.headers;
    return config;
  },
  error => {
    Promise.reject(error)
  });
axiosInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  const errorCode = error?.response?.status;
  if (errorCode === 401) {
    let result = await AuthenticationController.ReLogin();
    if ((!originalRequest._retry) && result) {
      originalRequest._retry = true;
      axios.defaults.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      return axiosInstance(originalRequest);
    }
  } else if (errorCode === 4001) {
    AuthenticationController.LogOut();
  }

  return Promise.reject(error);
});
export default axiosInstance;
