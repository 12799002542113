import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ConditionMakerComponent from '../Condition/ConditionMakerComponent';
import Inbox from './Inbox';

const WMSRoutes = () => {
    const mainroute = '/env/wms';
    return (
        <Switch>
            <Route path={mainroute + "/inbox"} component={Inbox} />
            <Route path={mainroute + "/humanresource"} exact render={()=><ConditionMakerComponent conditionid={'HumanResourceF4V1'} />}  />
            <Route path={mainroute + "/reports"} exact render={()=><div>Reports</div> }  />
        </Switch>
    );
};

export default WMSRoutes;