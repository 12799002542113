import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import NavbarStandard from '../navbar/NavbarStandard';
import FooterStandard from './FooterStandard';
import Section from '../common/Section';
import SectionHeader from './SectionHeader';
import { isIterableArray } from '../../helpers/utils';
import Process from './Process';
import aboutList from '../../data/feature/aboutList';

const AboutUs = ({ location, match }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return (
        <Fragment>
            <NavbarStandard location={location} match={match} />
            <Section>
                <SectionHeader
                    title="About our team"
                    subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                />
                {isIterableArray(aboutList) && aboutList.map((process, index) => <Process key={index} {...process} />)}
            </Section>
            <FooterStandard />
        </Fragment>
    );
};

export default AboutUs;
