import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, CustomInput, Row, Table } from 'reactstrap';
import CellControl from './CellControl';
import moment from 'jalali-moment'
import { Fragment } from 'react';
import Commands from './Commands';
import RowEditor from '../EngineDeveloper/FormBuilder/RowEditor';
import { ThemeDivider } from './ThemeControl';
import DroppableArea from './DroppableArea';
import DraggableItem from './DraggableItem';
const ListForm = ({ Form, DataListForm, CardOff, DM, Editor, ...rest }) => {
    const [listdata, setListData] = useState()
    useEffect(() => {
        setListData(DataListForm?.data)
    }, [DataListForm?.data])
    const [loading, setLoading] = useState(false);
    const onChange = async (value, pid, refrence) => {
        await DM.ChangeData(value, pid, refrence);
    };
    const RowDBLClick = (instance) => {
        if(rest.RowDoubleClick)
            rest.RowDoubleClick(instance);
        if (Editor) {
            DM.SendToEditor(instance, Form.FormID, Editor);
        }
    }
    const FillProp = (propid) => {
        if (propid) {
            DM.FillProp(Form.FormID, propid);
        }
    }
    const AddToDrop = (content, rowIndex, colIndex, control) => {
        if(rest.mode==='design')
        return <DroppableArea droppableId={rowIndex + '-' + colIndex}>
            <DraggableItem draggableId={rowIndex + '-' + colIndex + '#' + control.pid} index={0} >
                {content}
            </DraggableItem>
        </DroppableArea>
        else return content;
    }
    return (<Fragment >
        {!CardOff ? <Row> <Commands commands={Form.Commands} DM={DM} formId={Form.FormID} type='1' /> </Row> : null}

        {Form?.rows[0].title ? <Row><ThemeDivider>{Form?.rows[0].title}</ThemeDivider></Row> : null}
        {rest.mode === 'design' ? <Row><RowEditor DM={DM} index={0} /></Row> : null}
        {rest.mode === 'design' ? <Row style={{ height: Form?.rows[0].height }} className='p-1 pb-2'  >
                    {
                        Form?.rows[0].controls.map((control, colIndex) => {
                            return (
                                <Col lg={control.col} xs='12' sm='6' md={control.col} key={'0-' + colIndex}>
                                    {AddToDrop(<CellControl  DM={DM} control={control}
                                        rowIndex={0} colIndex={colIndex} {...rest}
                                         />,0,colIndex,control)}
                                </Col>
                            )
                        })
                    }
        </Row>:
        <div style={{minHeight:'320px',overflowX:'auto'}} >
        <Table hover striped   >
            <thead>
                <tr key='headRow'>
                    {
                        Form?.rows[0].controls.map((control,colIndex) => (<Fragment key={control.pid}>

                                <th className='text-truncate' col={control.col}  >
                                    {DM.ActionType === 'template' ?
                                        <Button className='btn-block'
                                            onClick={() => FillProp(control.pid)}
                                            color={
                                                DataListForm?.data?.fillProp?.findIndex(x => x === control.pid) > -1
                                                    ? 'primary' : 'light'
                                            } >{control.title}</Button> : control.title
                                    }
                                </th>
                        </Fragment>))
                    }
                </tr>
            </thead>
            <tbody>
                {
                    loading ? null :
                        listdata?.map((instance, index) => (
                            <tr onDoubleClick={(e) => { e.preventDefault(); RowDBLClick(instance) }} key={instance.ID + index}>
                                {
                                    Form.rows[0].controls.map((control, colIndex) => {
                                        let resultlist = instance.Prop?.find((p) => p.PID === control.pid);
                                        if ((control?.Editable || DM.ActionType === 'template') && !rest.isReadonly) {
                                            return (
                                                <td className={"p-1 m-0 col-md-" + control.col} key={instance.ID + '-' + colIndex + '-' + index}>
                                                    <CellControl TitleOff={true} DM={DM} value={resultlist?.IPV} control={control}
                                                        onChange={onChange} refrence={instance} prop={resultlist} />
                                                </td>
                                            )
                                        }
                                        else {
                                            let pvalue = '';
                                            if (resultlist !== null && resultlist !== undefined) {
                                                pvalue = resultlist.DIS ? resultlist.DIS : resultlist.IPV;
                                                if (control.controlType === "TimePeriod" || control.controlType === "DatePeriod")
                                                    pvalue = moment(resultlist.IPV, 'MM/DD/YYYY')
                                                        .locale('fa')
                                                        .format('YYYY/M/D');
                                            }
                                            return <td  key={control.pid + index + colIndex}>{pvalue}</td>
                                        }
                                    })
                                }
                            </tr>
                        ))
                }
            </tbody>
        </Table></div>}
    </Fragment>);
}
export default ListForm;
ListForm.propTypes = {
    Form: PropTypes.object.isRequired,
};
//<CustomInput type="switch" id={control.pid + 'switch'}
//    key={control.pid + 'switch'}
//    checked={Form.fillProp?.findIndex(x => x === control.pid) > -1}
//    onChange={() => FillProp(control.pid)} />