import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Dashboard from '../components/dashboard/projctly/Dashboard';
import NavbarTop from '../components/navbar/NavbarTop';
import NavbarVertical from '../components/navbar/NavbarVertical';
import AppContext from '../context/Context';
import { getPageName } from '../helpers/utils';
import loadable from '@loadable/component';
import WMSRoutes from '../wms/WMSRoutes';

const DashboardRoutes = loadable(() => import('./DashboardRoutes'));

const DashboardLayout = ({ location }) => {
  const { isFluid, isVertical, navbarStyle } = useContext(AppContext);

  const isKanban = getPageName('kanban');

  useEffect(() => {
    DashboardRoutes.preload();
    
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  console.log(location)
  return <>
    {
      //AuthenticationController.IsLogin() ?
      <div className={isFluid || isKanban ? 'container-fluid' : 'container'}>
        {isVertical && <NavbarVertical isKanban={isKanban} navbarStyle={navbarStyle} />}
        <div className="content">
          <NavbarTop />
          <Switch>
            <Route path="/env" exact component={Dashboard} />
            <WMSRoutes path="/env/wms"/>
            <DashboardRoutes />
          </Switch>
          {/* {!isKanban && <Footer />} */}
        </div>
        {/* <SidePanelModal path={location.pathname} /> */}
      </div>
      //: <h2>You must login first</h2>
    }
  </>
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;
