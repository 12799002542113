import { version } from './config';
const mainroute = '/env';
export const homeRoutes = {
  name: 'Dashboard',
  to: mainroute,
  exact: true,
  icon: 'chart-pie',
};
export const inboxRoutes = {
  name: 'Work Managment',
  to: '/',
  icon: 'book',
  children: [
    { to: mainroute + '/wms/inbox', name: 'Inbox', exact: true },
    { to: mainroute + '/wms/humanresource', name: 'Personel List', exact: true },
    { to: mainroute + '/wms/reports', name: 'Reports', exact: true }
  ]
};
export const projectRoutes = {
  name: 'My Projects',
  to: '/',
  exact: true,
  icon: ['fab', 'trello'],
  children: [
    { to: mainroute + '/ProjectHandler', name: 'Projects', exact: true },
    { to: mainroute + '/compony', name: 'Company', exact: true },
    { to: mainroute + '/jobdescriptions', name: 'Job Descriptions', exact: true },
    { to: mainroute + '/hiring', name: 'Hiring', exact: true },
    { to: mainroute + '/requestlist', name: 'Request List', exact: true },
    { to: mainroute + '/profile', name: 'Profile', exact: true }
  ]
};
export const jobsRoutes = {
  name: 'Find jobs',
  to: '/',
  exact: true,
  icon: 'book',
  children: [
    { to: mainroute + '/requestconlist', name: 'Jobs' },
    { to: mainroute + '/job/E11C1F0V1/@personid', name: 'Resume', exact: true },
    { to: mainroute + '/settings', name: 'Setting' }
  ]
};
export const SupportRoutes = {
  name: 'Support',
  to: '/',
  exact: true,
  icon: 'phone',
  children: [
    { to: mainroute + '/support/tickets', name: 'My tickets' },
    { to: mainroute + '/support/newTicket', name: 'New Ticket', exact: true },
    { to: mainroute + '/support/faq', name: 'FAQ', exact: true }
  ]
};
export const WMS = {
  name: 'Support',
  to: '/',
  exact: true,
  icon: 'phone',
  children: [
    { to: mainroute + '/inbox', name: 'Inbox' },
    { to: mainroute + '/Reports', name: 'New Ticket', exact: true },
    { to: mainroute + '/support/faq', name: 'FAQ', exact: true }
  ]
};
export default [
  homeRoutes,
  inboxRoutes,
  projectRoutes,
  jobsRoutes,
  SupportRoutes,

];
