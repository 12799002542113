import { faPlus } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Row, Table } from 'reactstrap';
import ButtonIcon from '../components/common/ButtonIcon';
import FalconCardHeader from '../components/common/FalconCardHeader';
import ConditionMaker from '../Engine/ConditionMaker';
import Parameters from '../Engine/Parameters';
import SideModal from '../EngineForms/SideModal';
import DutyHandler from './DutyHandler';
import { Duty } from './wmsContext';

const Inbox = ({ location }) => {
    const [isOpenSidePanel, setIsOpen] = useState();
    const [duties, setDuties] = useState();
    useEffect(() => {
        const fetch = async () => {
            if (!duties) {
                let condition = new ConditionMaker('E15C5');
                let personid = await Parameters.GetValue('@personid');
                condition.AddCondition('PC38', '=', personid, 'and');
                condition.AddCondition('PC43', '<>', 'E15C6I4');
                let result = await condition.GetResult();
                console.log(result)
                setDuties(result);
            }
        }
        fetch();
    })
    const toggleDutyHandler = () => {
        setIsOpen(!isOpenSidePanel);
    }
    return (<Card>
        <FalconCardHeader title='Task List'>
<ButtonIcon color='primary' onClick={()=>setIsOpen(true)} icon={faPlus}/>
        </FalconCardHeader>
        <Table hover striped >
            <thead>
                <tr>
                    <th>
                        #
                    </th>
                    <th>
                        Title
                    </th>
                    <th>
                        Status
                    </th>
                    <th>
                        Due Time
                    </th>
                    <th>
                        Deadline
                    </th>
                    <th>
                        Exp Time
                    </th>
                    <th>
                        Action
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    duties?.map((ins, index) => {
                        let duty = new Duty(ins);
                        return (<tr>
                            <td>{index + 1}</td>
                            <td>{duty.Title}</td>
                            <td>{duty.GetProperty('PC43')?.DIS}</td>
                            <td>{duty.StartTime}</td>
                            <td>{duty.EndTime}</td>
                            <td>{duty.ExpectedTime}</td>
                            <td><Button onClick={async()=>{
                                duty.TaskStatus='E15C6I4'
                                if(await duty.SaveAsync()){
                                    duties.splice(index,1);
                                    setDuties([...duties]);
                                }
                            }} >Done</Button></td>
                        </tr>)
                    })
                }
            </tbody>
        </Table>
        <SideModal isOpen={isOpenSidePanel} toggle={toggleDutyHandler}>
            <DutyHandler style={{ height: '100%' }} Cancel={() => setIsOpen(false)} />
        </SideModal>
    </Card>);
};

export default Inbox;