import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import {  Nav, NavItem, NavLink } from 'reactstrap';


const breakpoint = 'lg';

const LandingLeftSideNavItem = () => {
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);

  return (
    <Nav navbar className="ml-auto">
      <NavItem>
        <NavLink tag={Link} to="#!" onClick={() => setShowRegistrationModal(!showRegistrationModal)}>
          Contact sales
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={Link} to="/aboutus" onClick={() => setShowRegistrationModal(!showRegistrationModal)}>
          About us
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default LandingLeftSideNavItem;
