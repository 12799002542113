import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Label } from 'reactstrap';
import uuid from 'uuid';
import DateControl from './DateControl';
import SearchControl from './SearchControl';
import { Utility } from '../Engine/Common';
import ComboMultiControl from './ComboMultiControl';
import ReadonlyObjectControl from './ReadonlyObjectControl';
import CellEditor from '../EngineDeveloper/FormBuilder/CellEditor';
import InstanceControl from './Controls/InstanceControl';
import classNames from 'classnames';
import GetDisplay from '../Engine/Lan/Context';
import Parameters from '../Engine/Parameters';
import FileControl from './FileControl';
const getControl = (control, mode, prop) => {
    let Tag = '';
    let type = '';
    if (control.IsReadOnly && mode !== 'template') {
        Tag = ReadonlyObjectControl;
        type = 'readonly';
    }
    else
        switch (control.controlType) {
            case "MultiLang_TextBox":
                break;
            case "ComboBox":
                if (mode === 'template') {
                    Tag = ComboMultiControl;
                    type = 'select1';
                }
                else {
                    Tag = Input;
                    type = 'select';
                }
                break;
            case "CheckBox":
                Tag = 'select'
                break;
            case "Slider_CheckBox":
                break;
            case "TreeView":
                break;
            case "SearchControl":
                Tag = SearchControl;
                type = control.sourceId;
                break;
            case "InstanceControl":
                Tag = InstanceControl;
                type = control.sourceId;
                break;
            case "UserBox":
                break;
            case "Weight":
                break;
            case "RaidoButton":
                break;
            case "TimePeriod":
            case "DatePeriod":
                Tag = DateControl
                type = 'Date';
                break;
            case "MultiLineText":
                Tag = Input
                type = 'textarea';
                break;
            case "File":
                Tag = FileControl
                type = 'File';
                console.log(Tag)
                break;
            case "AITextBox":
            default:
                Tag = Input
                type = 'text';
                break;
        }
    return { Tag, type };
}

const CellControl = ({ control, onChange, labelClassName, TitleOff, refrence, prop, DM, ...rest }) => {
    const [DIPV, setDIPV] = useState();
    useEffect(() => {
        const getValue = async () => {
            let value = await Parameters.GetValue(control.DIPV);
            setDIPV(value)
        }
        if (refrence && control.DIPV) {
            if (!DIPV)
                getValue();
            if (DIPV)
                onChange(DIPV, control.pid, refrence)
        }
    }, [prop, refrence])
    const { Tag, type } = getControl(control, DM?.ActionType ?? rest.mode, prop);
    const [isOpen, setIsOpen] = useState();
    const inputId = uuid();
    const bsSize = 'sm';
    const onClick = (e) => {
        if (e.detail === 2) {
            setIsOpen(true);
        }
    }
    const toggle = () => {
        setIsOpen(!isOpen);
    }
    let source = prop?.stepSource ?? control?.source;
    const ValueChanged = (event,TP) => {
        let value = event?.target?.value;
        if (type === 'select')
            value = control?.source.find(x => x.id === event.target.value)
        else if (Utility.IsID(type) || type === 'select1')
            value = event
        else if (type === 'Date' || type==='File')
            value = event
        onChange(value, control.pid, refrence,TP)
    }
    return (< >
        {!TitleOff && type !== 'Date' && control.title !== '' && (
            <Label onClick={onClick} for={inputId} className={' text-truncate pb-0 mb-0'}>
                {control.title}
            </Label>
        )}
        <Tag bsSize={bsSize} type={type} value={prop?.IPV ?? ''} prop={prop}
            title={control.title} style={type === 'textarea' ? { height: '85%' } : null}
            control={control} dm={type === 'select1' ? DM : ''}
            refrence={refrence}
            className={classNames({ 'border-danger': prop?.hasError })}
            onChange={ValueChanged} id={inputId} >
            {type === 'select' ? (<>
                <option value="Unselected">
                    {GetDisplay('noSelect')}
                </option>
                {
                    source?.map((item) => {
                        return (
                            <option key={item.id} value={item.id}>
                                {item.display}
                            </option>
                        );
                    })
                }</>) : null
            }
        </Tag>
        {prop?.hasError && <span className="text-danger fs--1">{control.required}</span>}
        {rest.mode === 'design' ? <><CellEditor toggle={toggle} DM={DM} isOpen={isOpen} {...rest} /> </> : null}
    </>
    );
};

CellControl.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    labelClassName: PropTypes.string
};

export default CellControl;
