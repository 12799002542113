import React from 'react';
import PropTypes from 'prop-types';
import { Button, CardBody } from 'reactstrap';
import { Utility } from '../Engine/Common';
import GetDisplay from '../Engine/Lan/Context';
const CommandSetting = [
    { command: 'Save', title: GetDisplay('save'), color: 'primary' },
    { command: 'New', title: GetDisplay('new'), color: 'secondary' },
    { command: 'Delete', title:  GetDisplay('delete'), color: 'danger' },
    { command: 'Save-New', title:  GetDisplay('save-new'), color: 'secondary' },
    { command: 'Refresh', title:  GetDisplay('refresh'), color: 'secondary' },
    { command: 'RefreshTemplate', title:  GetDisplay('refresh-template'), color: 'secondary' },

]
const Commands = ({ commands, DM, type,formId,callback }) => {
    let result = [];
    const Commander = async (command, type, ides = []) => {
        switch (command) {
            case 'Save':
                if (DM.ActionType === 'template')
                    DM.Save();
                else {
                    for (var i = 0; i < ides.length; i++) {
                        await DM.Save(ides[i], callback);
                    }
                }
                break;
            case 'New':
                for (var i = 0; i < ides.length; i++) {
                    DM.New(ides[i], Utility.GetFormType(ides[i]));
                }
                break;
            case 'Save-New':
                if (DM.ActionType === 'template')
                    DM.Save();
                else {
                    for (var i = 0; i < ides.length; i++) {
                        await DM.Save(ides[i], callback);
                    }
                }
                for (var i = 0; i < ides.length; i++) {
                    DM.New(ides[i], Utility.GetFormType(ides[i]));
                }
                break;
            case 'Refresh':
                    for (var i = 0; i < ides.length; i++) {
                        await DM.Refresh(ides[i]);
                    }
                break;
            case 'Delete':
                break;
        }
    }
    if (type === '3') {
        commands.map((item) => {
            if (item.Order?.length > 0) {
                let commandSetting = CommandSetting.find(x => x.command === item.Command);
                result = [...result, <Button className="mr-2" key={item.Command} outline color={commandSetting.color}
                    value={item.Command} onClick={() => Commander(item.Command, type, item.Order)} > {commandSetting.title}</Button >];
            }
            else if (!item.Order){
                let commandSetting = CommandSetting.find(x => x.command === item);
            result = [...result, <Button className="mr-2" key={item} outline color={commandSetting.color}
                onClick={() => Commander(item, type, [formId])} > {commandSetting.title}</Button >];
            }
        })
    }
    else {
        commands.map(command => {
            let commandSetting = CommandSetting.find(x => x.command === command);
            result = [...result, <Button className="mr-2" key={command} outline color={commandSetting.color}
                onClick={() => Commander(command, type, [formId])} > {commandSetting.title}</Button >];
        })
    }
    return <CardBody><div className='float-right' type="inline" >{result}</div></CardBody>;
}
export default Commands;
Commands.propTypes = {
    commands: PropTypes.array.isRequired,
};