import React, { useState } from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import editing from '../assets/img/illustrations/4.png';
const Starter = ({ isEmployee, isEmployer }) => {
  return (
    <Card>
      <CardBody className="overflow-hidden p-lg-2">
        <Row className="align-items-center justify-content-between">
          <Col lg={6} className="pl-lg-2 my-5 text-center text-lg-left">
            <CardBody>
              {!isEmployer ? <div className='p-2'>
                <h3>Start hiring</h3>
                <p className="lead"> Projctly’s web portal will allow for easy and direct communication between the companies and candidates. By using PROJCTLY, professionals will be able to apply for any project they are interested in and companies will be able to search for the most suitable professionals for each position of the project team.</p>
                <Link className="btn btn-falcon-success" to="/env/hiring">
                  Start
                </Link>
              </div> : null}
              {!isEmployee ? <div className='p-2 mt-2'>
                <h3>Post a Resume</h3>
                <p className="lead">You can create an account and post your resume for FREE! Employers monitor your skillset and pick you up for their projects.</p>
                <Link className="btn btn-falcon-primary" to="/env/job/E11C1F0V1/@personid">
                  Post
                </Link>
              </div> : null}
            </CardBody>
          </Col>
          <Col lg={6}>
            <img src={editing} className="img-fluid" alt="" />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
export default Starter;
