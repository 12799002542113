import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Starter from '../../../Projctly/Starter';

const Dashboard = () => {
const [isEmployer,setIsEmployer]= useState(false);
const [isEmployee,setIsEmployee]= useState(false);
  useEffect(() => {
   
    toast(
      <Fragment>
        Welcome to <strong>Projctly</strong>!<br />
      </Fragment>
    );
  }, []);

  return (
    <Fragment>
        {isEmployer || isEmployee?null: <Starter />}
    </Fragment>
  );
};

export default Dashboard;
