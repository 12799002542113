import React, { Component } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Label } from 'reactstrap';
import App from '../App';
import { AuthenticationController } from './Authentication';

export default class ErrorHandler extends Component {
    state = {
        error: null, ErrorInfo: null
    }
    componentDidCatch(error, errorInfo) {
        this.setState({
            error, errorInfo
        })
    }
    static async CominicationError(ex) {
        console.log(ex);
        if (ex?.response?.status === 404)
            console.log('404');
        if (ex?.response?.status === 401) {
        }
        if (ex?.response?.status >= 500) {
            toast.error(ex.response.data.detail)
        }
        console.log(ex?.response);
    }

    render() {
        if (this.state.error !== null) {
            console.log(this.state.error.toString());
            return <Label>{this.state.error.toString()}</Label>
        }
        return this.props.children;
    }
}