import { faCogs } from '@fortawesome/free-solid-svg-icons';
import React, { Component } from 'react';
import { Card, CardBody, Label, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import ButtonIcon from '../components/common/ButtonIcon';
import { Utility } from '../Engine/Common';
import ObjectClassController from '../Engine/ObjectClassController';
import Parameters from '../Engine/Parameters';
import RootFormEditor from '../EngineDeveloper/FormBuilder/RootFormEditor';
import RootPanelEditor from '../EngineDeveloper/PanelBuilder/RootPanelEditor';
import DataForm from './DataForm';
import DataManager from './DataManager';
import ListForm from './ListForm';
import PanelForm from './PanelForm';
import { ThemeCardHeader } from './ThemeControl';

export default class FormManager extends Component {
    constructor(props) {
        super(props);
        // let DM = new DataManager(this);
        // this.state = {
        //     ...this.state,
        //     DM
        // }
    }
    state = {
        LastFormId: '',
        FormStructuer: null,
        FormType: '',
        FormData: undefined,
        DM: new DataManager(this),
        loadData: true,
        modalComp: false
    }
    async componentDidMount() {

        if (this.props.builder)
            this.props.builder.DM = this.state.DM;
        await this.Initialiez();
    }
    async componentDidUpdate() {
        await this.Initialiez();
    }
    initialize = false;
    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }
    async Initialiez() {
        if (this.props.formId && (this.state.LastFormId !== this.props.formId
            || this.state.Mode !== this.props.mode && !this.initialize)) {
            this.initialize = true;
            //this.state.DM=new DataManager(this);
            let DM = this.state.DM;
            
            DM.MainFormID = this.props.formId;
            DM.ActionType = this.props.mode;
            let FormType = this.props.FormType;
            if (!FormType)
                FormType = Utility.GetFormType(this.props.formId);
            let FormStructuer = this.props.FormStructuer;
            if (!FormStructuer)
                FormStructuer = await ObjectClassController.GetFormAsync(this.props.formId, null
                    , FormType);
            if (FormStructuer && FormType !== '3') {
                FormStructuer = await ObjectClassController.FillForm(this.props.formId);
            }
            if (FormStructuer) {
                await DM.InitialTemplate(FormStructuer);
                if (this.props.onChange)
                    this.state.DM.onChangeProp = this.props.onChange;
                if (this.props.FeedBack)
                    this.state.DM.feedBack = this.props.FeedBack;
                this.setState({
                    ...this.state,
                    LastFormId: this.props.formId,
                    Mode: this.props.mode,
                    FormStructuer,
                    DM
                });
            }

        }

        if (this.props.Data && this.props.Data !== this.state.FormData) {
            this.state.DM.SetData(this.state.LastFormId, this.props.Data);
            this.setState({
                ...this.state,
                FormData: this.props.Data
            })
        }
        if (this.props.insId && this.props.insId !== this.state.insId) {
            this.setState({
                ...this.state,
                insId: this.props.insId
            })
            let insId = this.props.insId;
            if (insId && !Utility.IsClassOrInstanceID(insId)) {
                insId = await Parameters.GetValue(insId)
            }
            if (Utility.IsInstanceID(insId))
                await this.state.DM.LoadDataAsync(insId);

        }
        if (this.props.location?.state?.Active === false) {
            this.props.location.state.Active = true;
            let formId = this.props.location?.state.formid
            let values = this.props.location?.state.values;
            this.state.DM.New(formId, Utility.GetFormType(formId));
            for (let i = 0; i < values.length; i++) {
                const element = values[i];
                await this.state.DM.ChangeData(element.value, element.pid, formId);
            }
        }
        this.initialize = false;
    }

    SelectedInstance(instance, editor) {
        let index = this.state.FormData.findIndex(x => x.formId === editor);
        let FormData = this.state.FormData;
        if (index >= 0)
            FormData.splice(index, 1);
        FormData = [...FormData, {
            formId: editor,
            data: instance
        }];
        this.setState({
            ...this.state,
            FormData
        })
    }
    Body(CardOff) {
        let result = null;
        let tempData;
        if (this.state.FormData)
            tempData = this.state.FormData.find(f => f.formId === this.props.formId);
        switch (this.state.FormStructuer.ShowType) {
            case 'vertical':
                result = <DataForm DataDataForm={tempData}
                    DM={this.state.DM}
                    CardOff={CardOff}
                    Form={this.state.FormStructuer} {...this.props} />
                break;
            case 'horizental':
                result = <ListForm
                    DataListForm={tempData}
                    DM={this.state.DM}
                    CardOff={CardOff} {...this.props}
                    Editor={this.props.Editor}
                    Form={this.state.FormStructuer}
                />
                break;
            case 'Panel':
                result = <PanelForm Data={this.state.FormData}
                    DM={this.state.DM}
                    CardOff={CardOff} {...this.props}
                    Form={this.state.FormStructuer} onSelected={this.props.onSelected ?? this.SelectedInstance.bind(this)}
                />
                break;
            default:
                result = <Label>No Control</Label>
                break;
        }
        return result;
    }
    toggleModal = () => {
        this.setState({
            ...this.state,
            modalComp: !this.state.modalComp
        })

    }
    render() {
        return (this.state.FormStructuer ?
            (this.props.CardOff ? this.Body(this.props.CardOff) :
                <Card style={this.props.style}>
                    {/* <React.StrictMode> */}
                    <ThemeCardHeader title={this.state.FormStructuer.title + (this.props.mode === 'design' ? ' ' + this.state.FormStructuer.FormID : '')} {...this.props} >
                        {this.state.loadData ? < Spinner color='primary' size='sm' /> : null}
                        {this.props.mode === 'design' ? <ButtonIcon icon={faCogs} color='transparent' onClick={this.toggleModal} /> : null}
                    </ThemeCardHeader>
                    <CardBody>
                        {this.Body()}
                    </CardBody>
                    {/* </React.StrictMode> */}
                    {this.props.mode === 'design' ?
                        <Modal size={this.state.FormStructuer.ShowType === 'Panel' ? 'xl' : 'auto'} toggle={this.toggleModal} isOpen={this.state.modalComp} centered>
                            <ModalHeader toggle={this.toggleModal} charCode="X" >
                                Form Data
                            </ModalHeader>
                            <ModalBody>
                                {this.state.FormStructuer.ShowType === 'Panel' ? <RootPanelEditor DM={this.state.DM} /> : <RootFormEditor DM={this.state.DM} />}
                            </ModalBody>
                        </Modal> : null}
                </Card>) : null
        );
    }
}
