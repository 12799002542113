
import BaseInstance, { NewInstance, NewProperty } from "../Engine/BaseInstance";
import ConditionMaker from "../Engine/ConditionMaker";
import { InstanceController } from "../Engine/InstanceController";
import Parameters from "../Engine/Parameters";
export class Duty extends BaseInstance {
    constructor(instance) {
        if (instance === undefined) {
            instance = NewInstance('E15C5');
            Duty.PIDs.map((pid) =>
                instance.Prop.push(NewProperty(pid)));
        }
        super(instance);
        this.ProgressPerson=0;
        this.ExpectedTime=0;
        this.TaskStatus='E15C6I1'
    }
    static PIDs = ['PC34', 'PC35', 'PC36', 'PC341', 'PC37', 'PC38', 'PC39', 'PC42', 'PC43', 'PC44', 'PC45', 'PC87', 'PC105', 'PC2', 'PC332', 'PC157', 'PC83', 'PC407', 'PC412', 'PC95', 'PC453'];
    set StartTime(value) {
        this.SetValue(Duty.PIDs[0], value);
    }
    get StartTime() {
        return this.GetValue(Duty.PIDs[0]);
    } set EndTime(value) {
        this.SetValue(Duty.PIDs[1], value);
    }
    get EndTime() {
        return this.GetValue(Duty.PIDs[1]);
    } set ExpectedTime(value) {
        this.SetValue(Duty.PIDs[2], value);
    }
    get ExpectedTime() {
        return this.GetValue(Duty.PIDs[2]);
    } set Comment(value) {
        this.SetValue(Duty.PIDs[3], value);
    }
    get Comment() {
        return this.GetValue(Duty.PIDs[3]);
    } set Supervisor(value) {
        this.SetValue(Duty.PIDs[4], value);
    }
    get Supervisor() {
        return this.GetValue(Duty.PIDs[4]);
    } set PersonTeam(value) {
        this.SetValue(Duty.PIDs[5], value);
    }
    get PersonTeam() {
        return this.GetValue(Duty.PIDs[5]);
    } set Documents(value) {
        this.SetValue(Duty.PIDs[6], value);
    }
    get Documents() {
        return this.GetValue(Duty.PIDs[6]);
    } set Importance(value) {
        this.SetValue(Duty.PIDs[7], value);
    }
    get Importance() {
        return this.GetValue(Duty.PIDs[7]);
    } set TaskStatus(value) {
        this.SetValue(Duty.PIDs[8], value);
    }
    get TaskStatus() {
        return this.GetValue(Duty.PIDs[8]);
    } set Prerequire(value) {
        this.SetValue(Duty.PIDs[9], value);
    }
    get Prerequire() {
        return this.GetValue(Duty.PIDs[9]);
    } set ProgressPerson(value) {
        this.SetValue(Duty.PIDs[10], value);
    }
    get ProgressPerson() {
        return this.GetValue(Duty.PIDs[10]);
    } set UnloadRefrence(value) {
        this.SetValue(Duty.PIDs[11], value);
    }
    get UnloadRefrence() {
        return this.GetValue(Duty.PIDs[11]);
    } set FileProp(value) {
        this.SetValue(Duty.PIDs[12], value);
    }
    get FileProp() {
        return this.GetValue(Duty.PIDs[12]);
    } set Rows(value) {
        this.SetValue(Duty.PIDs[13], value);
    }
    get Rows() {
        return this.GetValue(Duty.PIDs[13]);
    } set Owner(value) {
        this.SetValue(Duty.PIDs[14], value);
    }
    get Owner() {
        return this.GetValue(Duty.PIDs[14]);
    } set ProjectID(value) {
        this.SetValue(Duty.PIDs[15], value);
    }
    get ProjectID() {
        return this.GetValue(Duty.PIDs[15]);
    } set TimeSpant(value) {
        this.SetValue(Duty.PIDs[16], value);
    }
    get TimeSpant() {
        return this.GetValue(Duty.PIDs[16]);
    } set WorkDescription(value) {
        this.SetValue(Duty.PIDs[17], value);
    }
    get WorkDescription() {
        return this.GetValue(Duty.PIDs[17]);
    } set ObjectiveStatus(value) {
        this.SetValue(Duty.PIDs[18], value);
    }
    get ObjectiveStatus() {
        return this.GetValue(Duty.PIDs[18]);
    } set Title(value) {
        this.SetValue(Duty.PIDs[19], value);
    }
    get Title() {
        return this.GetValue(Duty.PIDs[19]);
    } set RepeatID(value) {
        this.SetValue(Duty.PIDs[20], value);
    }
    get RepeatID() {
        return this.GetValue(Duty.PIDs[20]);
    }
}

export class Project extends BaseInstance {
    constructor(instance) {
        if (instance === undefined) {
            instance = NewInstance('E15C1');
            Project.PIDs.map((pid) =>
                instance.Prop.push(NewProperty(pid)));
        }
        super(instance);
    }
    static PIDs = ['PC88', 'PC13', 'PC14', 'PC15', 'PC33', 'PC87', 'PC125', 'PC36', 'PC83', 'P24'];
    set Name(value) {
        this.SetValue(Project.PIDs[0], value);
    }
    get Name() {
        return this.GetValue(Project.PIDs[0]);
    } set StartDate(value) {
        this.SetValue(Project.PIDs[1], value);
    }
    get StartDate() {
        return this.GetValue(Project.PIDs[1]);
    } set EndDate(value) {
        this.SetValue(Project.PIDs[2], value);
    }
    get EndDate() {
        return this.GetValue(Project.PIDs[2]);
    } set Projects(value) {
        this.SetValue(Project.PIDs[3], value);
    }
    get Projects() {
        return this.GetValue(Project.PIDs[3]);
    } set Responsible(value) {
        this.SetValue(Project.PIDs[4], value);
    }
    get Responsible() {
        return this.GetValue(Project.PIDs[4]);
    }
    set Compony(value) {
        this.SetValue(Project.PIDs[5], value);
    }
    get Compony() {
        return this.GetValue(Project.PIDs[5]);
    } set ParentRight(value) {
        this.SetValue(Project.PIDs[6], value);
    }
    get ParentRight() {
        return this.GetValue(Project.PIDs[6]);
    } set ExpectedTime(value) {
        this.SetValue(Project.PIDs[7], value);
    }
    get ExpectedTime() {
        return this.GetValue(Project.PIDs[7]);
    } set TimeSpant(value) {
        this.SetValue(Project.PIDs[8], value);
    }
    get TimeSpant() {
        return this.GetValue(Project.PIDs[8]);
    } set ProjectFeature(value) {
        this.SetValue(Project.PIDs[9], value);
    }
    get ProjectFeature() {
        return this.GetValue(Project.PIDs[9]);
    }
}

export class HumanResource extends BaseInstance {
    constructor(instance) {
        if (instance === undefined) {
            instance = NewInstance('E15C2');
            HumanResource.PIDs.map((pid) =>
                instance.Prop.push(NewProperty(pid)));
        }
        super(instance);
        this.WorkHoursPerMonth = 0;
        this.Salary = 0;
    }
    static PIDs = ['PC29', 'PC30', 'PC27', 'PC31', 'P13', 'P30'];
    set WorkHoursPerMonth(value) {
        this.SetValue(HumanResource.PIDs[0], value);
    }
    get WorkHoursPerMonth() {
        return this.GetValue(HumanResource.PIDs[0]);
    } set Salary(value) {
        this.SetValue(HumanResource.PIDs[1], value);
    }
    get Salary() {
        return this.GetValue(HumanResource.PIDs[1]);
    } set Person(value) {
        this.SetValue(HumanResource.PIDs[2], value);
    }
    get Person() {
        return this.GetValue(HumanResource.PIDs[2]);
    }

    set WorkDays(value) {
        this.SetValue(HumanResource.PIDs[3], value);
    }
    get WorkDays() {
        return this.GetValue(HumanResource.PIDs[3]);
    } set Compony(value) {
        this.SetValue(HumanResource.PIDs[4], value);
    }
    get Compony() {
        return this.GetValue(HumanResource.PIDs[4]);
    } set EmployeStatus(value) {
        this.SetValue(HumanResource.PIDs[5], value);
    }
    get EmployeStatus() {
        return this.GetValue(HumanResource.PIDs[5]);
    }
}
let HumanList;
const GetHumanResources = async () => {
    if (!HumanList) {
        let condition = new ConditionMaker('E15C2');
        let company = await Parameters.GetValue('@orgid')
        condition.AddCondition(HumanResource.PIDs[4], '=', company);
        let result = await condition.GetResult();
        HumanList = [];
        for (let i = 0; i < result.length; i++) {
            const human = new HumanResource(result[i]);
            if (human.EmployeStatus === 'O31E12C10I1') {

                let person = await InstanceController.LoadInstanceAsync(human.Person);
                HumanList = [...HumanList,new BaseInstance(person)];
                console.log( HumanList[0].Instance.DIS)
            }
        }
    }
    return HumanList;
}
export { GetHumanResources };