import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { Card, CardBody, Modal, ModalBody, Nav, NavItem, NavLink } from 'reactstrap';
import { AuthenticationController } from '../../Engine/Authentication';

import Login from '../auth/basic/Login';
import Registration from '../auth/basic/Registration';
import NavbarDropdown from './NavbarDropdown';

const breakpoint = 'lg';

const LandingRightSideNavItem = () => {
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [isLogin, setLogin] = useState(AuthenticationController.IsLogin());

  return (
    <Nav navbar className="ml-auto">
      {!isLogin ?
        <NavbarDropdown title="Login" right>
          <Card className="navbar-card-login shadow-none">
            <CardBody className="fs--1 font-weight-normal p-4">
              <Login />
            </CardBody>
          </Card>
        </NavbarDropdown>
        : <NavItem>
          <NavLink tag={Link} to="/env" onClick={() => setShowRegistrationModal(!showRegistrationModal)}>
            {AuthenticationController.FullName()}
          </NavLink>
        </NavItem>}
      <NavItem>
        <NavLink tag={Link} to="#!" onClick={() => setShowRegistrationModal(!showRegistrationModal)}>
          Register
        </NavLink>
        <Modal isOpen={showRegistrationModal} centered toggle={() => setShowRegistrationModal(!showRegistrationModal)}>
          <ModalBody className="p-0">
            <Card>
              <CardBody className="fs--1 font-weight-normal p-4">
                <Registration />
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
      </NavItem>
    </Nav>
  );
};

export default LandingRightSideNavItem;
