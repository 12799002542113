import KamranForouzan from '../../assets/img/team/KamranForouzan.jpg';
import SanazRajabali from '../../assets/img/team/SanazRajabali.jpg';
import AliShariat from '../../assets/img/team/AliShariat.jpg'
import AlirezaAlikhani from '../../assets/img/team/AlirezaAlikhani.jpg'
import AminSavalehToreh from '../../assets/img/team/AminSavalehToreh.jpg'
export default [
  {
    iconText: 'Chief Executive Officer',
    color: 'info',
    title: 'Kamran Forouzan',
    description:
      `Kamran Forouzan was born in Tehran. He graduated from a school belonging to the most talented people in Tehran. He studied industrial engineering at the university. Then he started working as a system analysis and design expert in several organizations and companies. After 5 years working, he decided to pursue a postgraduate degree in business. Upon graduation in MBA, he changed his job orientation and started working in the business. 
      To Kamran, the business world is fascinating because it is life, real, unique, and full of bittersweet memories. He has attained the peak of his ability and confidence to execute challenging and complex projects.
      `,
    image: KamranForouzan,
    inverse: true
  },
  {
    iconText: 'Marketing Director and Branding Manager',
    color: 'info',
    title: 'Sanaz Rajabali',
    description:
      `Sanaz Rajabali has an academic background as a bachelor’s degree and master’s degree in medical engineering. She has 17 years experience in this field that has exposed her to become familiar with most medical products and processes governing the production, maintenance, repairs, calibration, Marketing, Sales, Post- Sales service and customer service. 
      Sanaz is currently working as the sales manager in Iran Medical Equipment Management Company which tends to be the only official sales representative of BBraun and Aesculap products produced in Germany for more than 30 years in Iran. 
      `,
    image: SanazRajabali
  },
  {
    iconText: 'Chief Technology Officer and Backend Manager',
    color: 'info',
    title: 'Ali Shariat',
    description:
      `Ali Shariati graduated from Industrial Engineering. As an expert in enterprise architecture (EA), Business Process Management, he implements ERP systems and the delivery of highly complex ERP, CRM, and manufacturing operation management, manufacturing execution system solution in chemical and petrochemical oil & gas and also after- sale services of Automotive industries. `,
    image: AliShariat,
    inverse: true
  },
  {
    iconText: 'Sales Director and Key Accounts Manager',
    color: 'info',
    title: 'Alireza Alikhani',
    description:
      `Alireza Alikhani is an Industrial Engineer. He started his career as a system analysis expert. Then moved on to become a project planning and control expert in large oil, gas and other petrochemical projects. He combines his professional work simultaneously with teaching. `,
    image: AlirezaAlikhani
  },
  {
    iconText: 'Chief Financial Officer and Resource Manager',
    color: 'info',
    title: 'Amin Savaleh Toreh',
    description:
      `Amin Savaleh is an innovative and nature- loving entrepreneur with the ability to speak Persian, English fluently and relative familiarity with Russian, Chinese and Korean languages respectively. He has been working for over 12 years in various departments of shipping companies where he has been designing and implementing logistic solutions for business owners and providing professional advice to start- up companies in the field of logistics. 
      Currently, Amin is the founder and owner of a trading company that focuses more on providing logistics solutions to other trading companies, factories and large industrial companies.
      `,
    image: AminSavalehToreh,
    inverse: true
  }
];